import { Link } from "react-router-dom";
import { FiArrowLeft, FiArrowDown, FiArrowRight } from "react-icons/fi";
import { useState, useEffect, useRef } from "react";
import "./FunnelAnalysis.css";
import heatmap from "../../assets/heatmap.png";
import heatmapActive from "../../assets/heatmap-active.png";

import HeatMap from "./HeatMap";
import {
  appendSegmentParamsToMatomoQuery,
  getMatomoDropdownFields,
  getMraUrls,
  getPathFromMatomo,
  isMatomoProject,
  isValidMatomoProject,
  runFunnelReport,
} from "../../helpers";
import { Spinner } from "react-bootstrap";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import CustomInput from "../CustomInput/CustomInput";
import Sortable from "sortablejs";
import { HiPlus } from "react-icons/hi";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { ToastContainer, toast } from "react-toastify";

export default function FunnelAnalysis(props) {
  const [funnel, setFunnel] = useState([]);
  const [funnelLoaded, setFunnelLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [view, setView] = useState("funnel");

  const [apiUrls, setApiUrls] = useState("");
  const [imageSrcs, setImageSrcs] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");

  const [pathModalOpen, setPathModalOpen] = useState(false);
  const [funnelName, setFunnelName] = useState("");
  const [steps, setSteps] = useState([]);
  const [orderedSteps, setOrderedSteps] = useState([]);

  const [pathValid, setPathValid] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);

  const [goalOptions, setGoalOptions] = useState([]);
  const [urlOptions, setUrlOptions] = useState([]);
  const [pathOptions, setPathOptions] = useState([]);
  const [pageTitleOptions, setPageTitleOptions] = useState([]);
  const [eventCategoryOptions, setEventCategoryOptions] = useState([]);
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const [eventActionOptions, setEventActionOptions] = useState([]);

  const [formattedPath, setFormattedPath] = useState(null);

  const [comparisonOptions, setComparisonOptions] = useState([
    { label: "Page Title", value: "pageName", desc: "lorem ipsum" },
    { label: "URL", value: "url", desc: "lorem ipsum" },
    { label: "Path", value: "path", desc: "lorem ipsum" },
    { label: "Goal", value: "goal", desc: "lorem ipsum" },
    { label: "Event Category", value: "eventCategory", desc: "lorem ipsum" },
    { label: "Event Name", value: "eventName", desc: "lorem ipsum" },
    { label: "Event Action", value: "eventAction", desc: "lorem ipsum" },
  ]);
  const [conditionOptions, setConditionOptions] = useState([
    { label: "Equals", value: "==" }, //dropdown
    // { label: "Not equals", value: "!=" }, //dropdown
    // { label: "Contains", value: "=@" }, //textbox
    // { label: "Does not contain", value: "!@" }, //textbox
    // { label: "Starts with", value: "=^" }, //textbox
    // { label: "Ends with", value: "=$" }, //textbox
  ]);

  function failFunnel() {
    setFunnel([]);
    setFunnelLoaded(true);
    setTotalPages(0);
  }

  useEffect(() => {
    async function fetchImages() {
      try {
        setImageSrcs([]);
        const urls = apiUrls.split("]>>>[");

        const device = props.heatmapFunnelFilters.phone
          ? "phone"
          : props.heatmapFunnelFilters.desktop
          ? "desktop"
          : props.heatmapFunnelFilters.tablet
          ? "tablet"
          : "";

        const imgs = await getMraUrls(
          props.selectedProject.MRAID ?? `${props.selectedProject.value}_`,
          urls,
          device,
        );
        setImageSrcs(imgs);
      } catch (e) {}
    }
    if (
      apiUrls &&
      view == "heatmap" &&
      props.selectedProject &&
      props.selectedProject.MRAID
    ) {
      fetchImages();
    }
  }, [
    apiUrls,
    view,
    props.heatmapFunnelFilters.phone,
    props.heatmapFunnelFilters.desktop,
    props.heatmapFunnelFilters.tablet,
  ]);

  useEffect(() => {
    props.setIsFunnel(true);
    props.setHideFunnelFilters(true);
    setFunnel([]);

    return () => {
      props.setIsFunnel(false);
      props.setHideFunnelFilters(false);
    };
  }, []);

  function resetModalOptions() {
    setGoalOptions([]);
    setPageTitleOptions([]);
    setEventCategoryOptions([]);
    setEventNameOptions([]);
    setEventActionOptions([]);
    setPathOptions([]);
    setUrlOptions([]);
  }

  useEffect(() => {
    if (formattedPath) {
      if (
        props.heatmapFunnelFilters.startDate &&
        props.heatmapFunnelFilters.endDate
      ) {
        getAndSetFunnelData(formattedPath);
      }
    } else {
      if (
        props.heatmapFunnelFilters.startDate &&
        props.heatmapFunnelFilters.endDate
      ) {
        reloadFunnel();
      }
    }
  }, [
    props.heatmapFunnelFilters.country,
    props.heatmapFunnelFilters.startDate,
    props.heatmapFunnelFilters.endDate,
    props.heatmapFunnelFilters.phone,
    props.heatmapFunnelFilters.desktop,
    props.heatmapFunnelFilters.tablet,
    props.heatmapFunnelFilters.unknownDevice,
    props.heatmapFunnelFilters.duration,
    props.heatmapFunnelFilters.localTime,
    props.heatmapFunnelFilters.browser,
    props.heatmapFunnelFilters.referrer,
    props.heatmapFunnelFilters.deviceBrand,
    props.heatmapFunnelFilters.resolution,
    props.heatmapFunnelFilters.formUse,
    props.heatmapFunnelFilters.city,
  ]);

  useEffect(() => {
    if (
      props.heatmapFunnelFilters.startDate &&
      props.heatmapFunnelFilters.endDate
    ) {
      setFormattedPath(null);
      reloadFunnel();
    }
  }, [props.heatmapFunnelFilters.pathType, props.heatmapFunnelFilters.tag]);

  async function buildManualPathDropdowns() {
    if (
      !isMatomoProject(props.selectedProject) ||
      props.selectedProject?.validMatomo === -1
    ) {
      setModalLoading(false);
      return;
    }

    let fields;

    try {
      fields = await getMatomoDropdownFields({
        params: {
          matomoUrl: props.selectedProject.matomoUrl,
          siteId: props.selectedProject.matomoId,
          apiToken: props.selectedProject.matomoApiToken,
        },
      });
    } catch (error) {
      setModalLoading(false);
      return;
    }

    if (!fields) {
      setModalLoading(false);
      return;
    }

    const mapOptions = (array) =>
      array.map((item) => ({ label: item, value: item }));

    if (fields) {
      setGoalOptions(mapOptions(fields.goals));
      setPageTitleOptions(mapOptions(fields.titles));
      setEventCategoryOptions(mapOptions(fields.categories));
      setEventNameOptions(mapOptions(fields.names));
      setEventActionOptions(mapOptions(fields.actions));
      setPathOptions(mapOptions(fields.urls));
      setUrlOptions(
        fields.urls.map((url) => {
          const fullUrl =
            props.selectedProject.rootUrl.replace(/\/$/, "") + url;
          return { label: fullUrl, value: fullUrl };
        }),
      );
    }
    setModalLoading(false);
  }

  useEffect(() => {
    setSteps([]); // reset manual path on project swap
    resetModalOptions();
    setFunnelName("");
    setModalLoading(true);
    buildManualPathDropdowns();

    if (props.heatmapFunnelFilters && props.selectedProject) {
      reloadFunnel();
    }
  }, [props.selectedProject]);

  function validateSteps(steps) {
    if (!funnelName) {
      return false;
    }

    for (const step of steps) {
      if (!step.name || !step.condition || !step.comparison) {
        return false;
      }
      if (isDropdownCondition(step) && !step.value) {
        return false;
      }
      if (isTextboxCondition(step) && !step.param) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    setPathValid(validateSteps(steps));
  }, [steps, funnelName]);

  function reloadFunnel() {
    setFunnelLoaded(false);
    if (props.selectedProject) {
      fetchFunnel();
    }
  }

  function formatForDateMatomoQuery(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function convertTimestampsToStringForDateMatomoQuery(
    startDateTimestamp,
    endDateTimestamp,
  ) {
    const startDate = new Date(startDateTimestamp);
    const endDate = new Date(endDateTimestamp);
    return `${formatForDateMatomoQuery(startDate)},${formatForDateMatomoQuery(
      endDate,
    )}`;
  }

  const parseAndCombineActivity = (data) => {
    data.funnel.forEach((funnelItem) => {
      const combinedActivity = {
        clicks: [],
        moves: [],
        scrolls: [],
        hesitations: [],
        frustrations: [],
      };

      funnelItem.activity.forEach((activityItem) => {
        const parsedActivity = JSON.parse(activityItem);

        function transformAndPush(
          activityType,
          parsedActivity,
          combinedActivity,
        ) {
          if (parsedActivity?.[activityType]) {
            const activity = parsedActivity[activityType];
            let transformedActivity = activity;

            if (parsedActivity.screen) {
              transformedActivity = activity.map((item) => {
                return {
                  x: item[0],
                  y: item[1],
                  w: parsedActivity.screen.w,
                  h: parsedActivity.screen.h,
                };
              });
            }

            combinedActivity[activityType].push(...transformedActivity);
          }
        }

        transformAndPush("clicks", parsedActivity, combinedActivity);
        transformAndPush("moves", parsedActivity, combinedActivity);
        transformAndPush("hesitations", parsedActivity, combinedActivity);
        transformAndPush("frustrations", parsedActivity, combinedActivity);

        if (parsedActivity?.scrolls) {
          combinedActivity.scrolls.push(...parsedActivity.scrolls);
        }
      });

      // Replace the original activity array with the combined activity
      funnelItem.activity = combinedActivity;
    });
    
    return data;
  };

  const handleError = (message) => {
    setErrorMsg(message);
    setFunnel([]);
    setFunnelLoaded(true);
  };

  async function fetchFunnel() {
    if (props.selectedProject === null) {
      handleError("Loading..");
      return;
    }

    const validMatomo = isValidMatomoProject(props.selectedProject);

    if (!validMatomo) {
      handleError("This feature requires a valid analytics setup");
      return;
    }

    if (!props.heatmapFunnelFilters.tag) {
      handleError("You have no goal tags set up!");
      return;
    }

    if (props.heatmapFunnelFilters.pathType === "ROI") {
      handleError("ROI path is a work in progress!");
      return;
    }

    const dateRange = convertTimestampsToStringForDateMatomoQuery(
      props.heatmapFunnelFilters.startDate,
      props.heatmapFunnelFilters.endDate,
    );

    // Wait for these values to load from selectedProject prop and sidebar filters
    const paramsWithoutSegment = {
      matomoUrl: props.selectedProject.matomoUrl,
      siteId: props.selectedProject.matomoId,
      apiToken: props.selectedProject.matomoApiToken,
      period: "range",
      date: dateRange,
      pathType: props.heatmapFunnelFilters.pathType,
      goal: {
        type: props.heatmapFunnelFilters.tag.type,
        value: props.heatmapFunnelFilters.tag.value, //"Our Services | North P&D Software Development & Design", // "Our Work | North P&D Software Development Projects",
      },
    };

    const params = appendSegmentParamsToMatomoQuery(
      paramsWithoutSegment,
      props,
      handleError,
    );

    getPathFromMatomo({ params: params })
      .then((data) => {
        if (data.status !== 200) {
          failFunnel();
          if (data.status === 500) {
            if (
              data.message ===
              "Visits log is deactivated globally. A user with super user access can enable this feature in the general settings."
            ) {
              setErrorMsg(
                "This feature requires the 'Visits log' to be enabled on Matomo",
              );
            } else {
              setErrorMsg("No data found with your selected filters");
            }
          }
          return;
        }
        const parsedData = parseAndCombineActivity(data);
        setFunnelData(parsedData);
      })
      .catch((error) => {
        failFunnel();
      });
  }

  function isDropdownCondition(step) {
    if (!step || !step.condition || !step.comparison) {
      return false;
    }
    return ["==", "!="].includes(step.condition.value);
  }

  function isTextboxCondition(step) {
    if (!step || !step.condition || !step.comparison) {
      return false;
    }
    return ["=@", "!@", "=^", "=$"].includes(step.condition.value);
  }

  function getComparisonPlaceholder(step) {
    if (!step || !step.comparison || !step.comparison.label) {
      return "Select a comparison";
    }
    return step.comparison.label;
  }

  const sortableRef = useRef(null);

  function setFunnelData(data) {
    const funnel = data.funnel.map((item, i) => {
      if (data.funnel[i + 1]) {
        const dropoff = parseFloat(
          (1 - data.funnel[i + 1].sessions / item.sessions).toFixed(2),
        );
        item.dropoff = isNaN(dropoff) ? 0 : dropoff; // Safeguard for NaN
      }
      return item;
    });

    setFunnel(funnel);
    setFunnelLoaded(true);
    setTotalPages(data.funnel.length);

    if (funnel.length === 0) {
      setErrorMsg("No data found with your selected filters");
    }

    const combinedUrls = funnel.map((f) => f.url).join("]>>>[");
    setApiUrls(combinedUrls);
  }

  useEffect(() => {
    if (pathModalOpen) {
      const initializeSortable = () => {
        const el = document.getElementById("items");
        if (el) {
          if (!sortableRef.current) {
            sortableRef.current = new Sortable(el, {
              handle: ".handle",
              animation: 150,
            });
          }
        }
      };

      // Use requestAnimationFrame to ensure the element is rendered
      const rafId = requestAnimationFrame(initializeSortable);

      // Cleanup function to cancel the requestAnimationFrame and destroy the Sortable instance
      return () => {
        cancelAnimationFrame(rafId);
        if (sortableRef.current) {
          sortableRef.current.destroy();
          sortableRef.current = null;
        }
      };
    }
  }, [pathModalOpen, steps, setSteps]);

  function handleManualFunnel() {
    setFunnelLoaded(false);
    setPathModalOpen(false);
    const newOrder = sortableRef?.current?.toArray();
    const oldOrder = [...newOrder].sort();
    const newSteps = [];

    for (let i = 0; i < newOrder.length; i++) {
      const stepIndex = oldOrder.indexOf(newOrder[i]);
      newSteps.push(steps[stepIndex]);
    }

    const formattedPath = newSteps.map((step) => {
      const formattedStep = {
        name: step.name,
        comparison: step?.comparison?.value,
        condition: step?.condition?.value,
      };

      if (isDropdownCondition(step)) {
        formattedStep.value = step?.value?.value;
      }

      if (isTextboxCondition(step)) {
        formattedStep.value = step?.param;
      }

      return formattedStep;
    });

    setFormattedPath(formattedPath);
    getAndSetFunnelData(formattedPath);
  }

  function getAndSetFunnelData(formattedPath) {
    setFunnelLoaded(false);
    const dateRange = convertTimestampsToStringForDateMatomoQuery(
      props.heatmapFunnelFilters.startDate,
      props.heatmapFunnelFilters.endDate,
    );

    const paramsWithoutSegment = {
      siteId: props.selectedProject.matomoId,
      apiToken: props.selectedProject.matomoApiToken,
      matomoUrl: props.selectedProject.matomoUrl,
      type: "matomo",
      period: "range",
      date: dateRange,
      path: formattedPath.map((step) => ({
        comparison: step.comparison,
        condition: step.condition,
        value: step.value,
        name: step.name,
      })),
    };

    const params = appendSegmentParamsToMatomoQuery(
      paramsWithoutSegment,
      props,
      handleError,
    );

    runFunnelReport({ params: params }).then((data) => {
      const parsedData = parseAndCombineActivity(data);
      setFunnelData(parsedData);
    });
  }

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={pathModalOpen}
        onRequestClose={() => {
          setPathModalOpen(false);
        }}
        contentLabel="Change the path"
        className="path-modal-shape hide-scrollbar"
        appElement={document.getElementById("root")}
        style={{
          overflow: "hidden",
          overlay: {
            background: "#0F0C22A6",
            zIndex: 10,
          },
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="fw-700 fc-black fs-24 lh-324">Change the path</h2>
          <button
            className="delete-confirm-x-btn"
            style={{ padding: "0" }}
            onClick={() => {
              setPathModalOpen(false);
            }}
          >
            <BsXCircle alt="X button" size="32px" color="#0F0C22" />
          </button>
        </div>
        <div
          className="d-flex align-items-center w-100-input"
          style={{ marginTop: "32px", marginBottom: "9px" }}
        >
          <CustomInput
            containerStyle={{ width: "100%" }}
            placeholder="Funnel name"
            containerClassName="observation-text-input-container w-100-input"
            inputClassNames="w-100-input"
            className={funnelName === "" ? "active" : ""}
            valueState={funnelName}
            onChange={(e) => setFunnelName(e.target.value)}
            value={funnelName}
          />
        </div>
        <div className="fw-500 fs-16 fc-grey lh-20">
          Use an explicit name to describe the goal you want to achieve with
          this funnel.
        </div>
        <div
          style={{ marginTop: "50px", marginBottom: "14px" }}
          className="fs-20 lh-27 fw-700 fc-black"
        >
          Steps
        </div>
        <div style={{ position: "relative" }}>
          <div className="step-index-numbering">
            {Array.from({ length: steps.length }, (_, i) => (
              <div
                key={i}
                className="vhc fs-18 fw-700 fc-black"
                style={{
                  width: "60px",
                  marginRight: "20px",
                  textAlign: "center",
                  marginBottom: "50px",
                }}
              >
                {i + 1}
              </div>
            ))}
          </div>
        </div>
        <div id="items" className="w-100">
          {steps.map((step, i) => (
            <div
              data-id={i}
              key={i}
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "74px",
              }}
            >
              <div
                className="handle"
                style={{ width: "90px", display: "flex", marginTop: "5px" }}
              >
                <i className="vhc fa-solid fa-grip-dots-vertical fs-15"></i>
                <div
                  className="vhc fs-18 fw-700 fc-black"
                  style={{
                    width: "60px",
                    marginRight: "20px",
                    textAlign: "center",
                  }}
                ></div>
              </div>
              <div
                className="d-flex align-items-center w-100-input no-margins-or-padding"
                style={{ marginBottom: "2px" }}
              >
                <div
                  style={{
                    width: "250px !important",
                  }}
                >
                  <CustomInput
                    containerStyle={{ width: "100%" }}
                    placeholder="Step name"
                    containerClassName="observation-text-input-container mw-250 w-100-input"
                    inputClassNames="w-100-input"
                    className={step.name === "" ? "active" : ""}
                    valueState={step.name}
                    onChange={(e) => {
                      const newSteps = [...steps];
                      newSteps[i].name = e.target.value;
                      setSteps(newSteps);
                    }}
                    value={step.name}
                  />
                </div>
                <div
                  className="path-change-dropdown-fix"
                  style={{
                    width: "200px",
                  }}
                >
                  <CustomDropdown
                    options={comparisonOptions}
                    onChange={(option) => {
                      const newSteps = [...steps];
                      newSteps[i].comparison = option;
                      newSteps[i].value = null;
                      setSteps(newSteps);
                    }}
                    value={steps[i].comparison}
                    placeholder="Comparison"
                    unstyled
                    blueChevron
                    menuOpenState={steps[i].comparisonOpen}
                    maxHeight={"150px"}
                    setMenuOpenState={(state) => {
                      const newSteps = [...steps];
                      newSteps[i].comparisonOpen = state;
                      setSteps(newSteps);
                    }}
                  />
                </div>
                <div
                  className="path-change-dropdown-fix"
                  style={{
                    width: "200px",
                  }}
                >
                  <CustomDropdown
                    options={conditionOptions}
                    onChange={(option) => {
                      const newSteps = [...steps];
                      newSteps[i].condition = option;
                      setSteps(newSteps);
                    }}
                    value={steps[i].condition}
                    placeholder="Condition"
                    unstyled
                    blueChevron
                    menuOpenState={steps[i].conditionOpen}
                    maxHeight={"150px"}
                    setMenuOpenState={(state) => {
                      const newSteps = [...steps];
                      newSteps[i].conditionOpen = state;
                      setSteps(newSteps);
                    }}
                  />
                </div>
                {isDropdownCondition(steps[i]) ? (
                  <div
                    className="path-change-dropdown-fix"
                    style={{
                      width: "334px",
                    }}
                  >
                    <CustomDropdown
                      options={
                        steps[i].comparison?.value === "goal"
                          ? goalOptions
                          : steps[i].comparison?.value === "url"
                          ? urlOptions
                          : steps[i].comparison?.value === "path"
                          ? pathOptions
                          : steps[i].comparison?.value === "pageName"
                          ? pageTitleOptions
                          : steps[i].comparison?.value === "eventCategory"
                          ? eventCategoryOptions
                          : steps[i].comparison?.value === "eventName"
                          ? eventNameOptions
                          : steps[i].comparison?.value === "eventAction"
                          ? eventActionOptions
                          : []
                      }
                      onChange={(option) => {
                        const newSteps = [...steps];
                        newSteps[i].value = option;
                        setSteps(newSteps);
                      }}
                      value={steps[i].value ?? null}
                      placeholder={getComparisonPlaceholder(steps[i])}
                      unstyled
                      blueChevron
                      menuOpenState={steps[i].valueOpen}
                      maxHeight={"150px"}
                      setMenuOpenState={(state) => {
                        const newSteps = [...steps];
                        newSteps[i].valueOpen = state;
                        setSteps(newSteps);
                      }}
                    />
                  </div>
                ) : isTextboxCondition(steps[i]) ? (
                  <div
                    style={{ width: "334px !important", marginLeft: "10px" }}
                  >
                    <CustomInput
                      containerStyle={{ width: "100%" }}
                      placeholder="Parameter"
                      containerClassName="observation-text-input-container mw-334 w-100-input"
                      inputClassNames="w-100-input"
                      className={step.param === "" ? "active" : ""}
                      valueState={step.param}
                      onChange={(e) => {
                        const newSteps = [...steps];
                        newSteps[i].param = e.target.value;
                        setSteps(newSteps);
                      }}
                      value={step.param}
                    />
                  </div>
                ) : (
                  <div style={{ width: "334px" }} className="pending-condition">
                    Please select a comparison and a condition.
                  </div>
                )}
              </div>
              <button
                style={{ width: "50px", textAlign: "right", marginTop: "10px" }}
                className="fc-black btn-none"
                onClick={() => {
                  setSteps(steps.filter((_, j) => j !== i));
                }}
              >
                <i className=" fa-regular fa-trash fs-20"></i>
              </button>
            </div>
          ))}
        </div>
        {steps.length < 10 && (
          <button
            className="btn-quaternary fw-700 d-flex align-items-center my-4"
            onClick={() => {
              if (steps.length < 10) {
                setSteps([
                  ...steps,
                  {
                    name: "",
                    condition: null,
                    comparison: null,
                    value: null,
                    param: "",
                    conditionOpen: false,
                    comparisonOpen: false,
                    valueOpen: false,
                  },
                ]);
              }
            }}
          >
            <HiPlus style={{ marginRight: "14px" }} />
            Add a step
          </button>
        )}

        <button
          type="button"
          onClick={() => {
            handleManualFunnel();
          }}
          disabled={!pathValid || steps.length < 1}
          className="btn-primary d-flex align-items-center"
          style={{ marginTop: "50px" }}
        >
          Continue
          <FiArrowRight
            size="17.78px"
            style={{
              marginLeft: "12px",
              marginTop: "2px",
            }}
          />
        </button>
      </Modal>

      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "158px",
            height: "24px",
            textAlign: "center",
            marginTop: "35px",
          }}
          className="fs-14 fw-700 fc-black"
          onClick={() => {
            props.setActiveMenuItem("dashboard");
          }}
        >
          <Link to={"/"} style={{ color: "#000" }}>
            <FiArrowLeft
              color="#000"
              size="24px"
              style={{
                marginRight: "10px",
                marginBottom: "2px",
              }}
            />
            Back to Dashboard
          </Link>
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <div>
                  <h2
                    style={{
                      marginTop: "36.5px",
                      width: "169px",
                      height: "32px",
                    }}
                    className="fw-700 fs-24 lh-324 fc-black"
                  >
                    Funnel analysis
                  </h2>
                  <div
                    style={{ marginTop: "10.5px", marginBottom: "24px" }}
                    className="fw-500 fs-16 fc-grey"
                  >
                    Look at everything you want to understand with LUXIFER AI.
                  </div>
                </div>
              </div>
              <div className="row" style={{ top: 283 }}>
                <div
                  onClick={() => setView("heatmap")}
                  className={`vhc chart-card-toggle-btn-${
                    view === "heatmap" ? "active" : "inactive"
                  }`}
                >
                  <img
                    style={{ height: "24px" }}
                    src={view === "heatmap" ? heatmapActive : heatmap}
                  ></img>
                </div>
                <div
                  onClick={() => {
                    setView("funnel");
                  }}
                  className={`vhc chart-card-toggle-btn-${
                    view === "funnel" ? "active" : "inactive"
                  }`}
                >
                  <i className="fa-regular fa-square-kanban fa-xl"></i>
                </div>
              </div>
            </div>
          </div>

          {!funnelLoaded ? (
            <div className="mb-32">
              <Spinner style={{ height: "44px", width: "44px" }} />
            </div>
          ) : funnel.length === 0 ? (
            <>
              {(props.selectedProject?.googleAnalyticsId ||
                props.selectedProject?.matomoUrl) && (
                <button
                  onClick={() => {
                    // if (props.selectedProject.matomoId) {
                    //   setPathModalOpen(true);
                    // } else {
                    toast.warn("Manual paths are a work in progress.");
                    //}
                  }}
                  className="btn-quaternary fw-700"
                >
                  Change the path
                </button>
              )}
              <h3 style={{ marginTop: "32px" }}>{errorMsg}</h3>
            </>
          ) : view === "heatmap" ? (
            <HeatMap
              funnel={funnel}
              funnelLoaded={funnelLoaded}
              totalPages={totalPages}
              imgSrcs={imageSrcs}
              setPathModalOpen={setPathModalOpen}
              pid={props.selectedProject.value}
              selectedProject={props.selectedProject}
              filters={props.heatmapFunnelFilters}
              MRAID={props.selectedProject.MRAID ?? ""}
            />
          ) : view === "funnel" ? (
            <>
              <button
                onClick={() => setPathModalOpen(true)}
                className="btn-quaternary fw-700"
              >
                Change the path
              </button>
              <div className="funnel-container">
                {funnelLoaded ? (
                  funnel.map((item, i) => {
                    let triangleHeight =
                      i === 0
                        ? funnel[i].dropoff * 100
                        : funnel[i - 1].height * funnel[i].dropoff;

                    let currentHeight =
                      i === 0
                        ? 100 - triangleHeight
                        : funnel[i - 1].height - triangleHeight;

                    if (funnel.length !== 1) {
                      if (i === funnel.length - 1) {
                        currentHeight = funnel[i - 1].height;
                        triangleHeight = 0;
                      }
                    } else {
                      currentHeight = 100;
                      triangleHeight = 0;
                    }
                    item.height = currentHeight;
                    return (
                      <div
                        key={i}
                        className={`funnel-item ${
                          i === 0
                            ? "funnel-item-bl funnel-item-border-left"
                            : ""
                        } ${i === funnel.length - 1 ? "funnel-item-br" : ""}`}
                      >
                        <div style={{ width: "100%" }}>
                          <div
                            className={`funnel-item-page ${
                              i === 0 ? "funnel-item-bl" : ""
                            } ${
                              i === funnel.length - 1 ? "funnel-item-br" : ""
                            }`}
                          >
                            <div className="fs-16 fw-500 lh224 fc-black">
                              STEP {i + 1}
                            </div>
                            <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                              {item.page}
                            </div>
                          </div>
                          <div className="funnel-item-sessions">
                            <div className="fs-16 fw-500 lh224 fc-grey">
                              Sessions
                            </div>
                            <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                              {item.sessions}
                            </div>
                          </div>
                          <div className="funnel-item-chart">
                            <div
                              style={{
                                height: `${
                                  100 - currentHeight - triangleHeight
                                }%`,
                              }}
                            ></div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: `${triangleHeight}%`,
                                backgroundColor: "transparent",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "101%",
                                  backgroundColor: "#EAE2FF",
                                  clipPath:
                                    "polygon(0% 0%, 100% 100%, 0% 100%)",
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#EAE2FF",
                                width: "100%",
                                height: `${currentHeight}%`,
                              }}
                            />
                          </div>
                          <div style={{ position: "relative" }}>
                            <button className="down-btn">
                              <FiArrowDown
                                color="#fff"
                                size="24px"
                                style={{
                                  transform:
                                    "translateX(-5px) translateY(-4px)",
                                }}
                              />
                            </button>
                          </div>

                          <div className="funnel-item-dropoff">
                            {i < funnel.length - 1 && (
                              <>
                                <div className="fs-24 fw-900 lh-336 vhc">
                                  {Math.floor(item.dropoff * 100)}
                                  {Math.round(item.dropoff * 1000) % 10 !==
                                    0 && (
                                    <span className="fs-20">
                                      ,{Math.round(item.dropoff * 1000) % 10}
                                    </span>
                                  )}
                                  <span className="fs-20">%</span>
                                </div>
                                <div
                                  style={{
                                    marginTop: "4px",
                                    marginBottom: "32px",
                                  }}
                                  className="fs-14 fw-500 lh-196 vhc"
                                >
                                  DROPOFF
                                </div>
                              </>
                            )}
                            <div
                              className="btn-center-container"
                              style={{
                                paddingTop:
                                  i === funnel.length - 1 ? "89px" : 0,
                              }}
                            >
                              <button
                                className="btn-quaternary w-100"
                                style={{ minWidth: "90px" }}
                              >
                                Optimize
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>Loading funnel data...</div>
                )}
              </div>
            </>
          ) : (
            <>Invalid View</>
          )}
        </div>
      </div>
    </div>
  );
}
